<script lang="ts" setup>
useHead({
    meta: [
        { name: "apple-mobile-web-app-title", content: "Allmedica.com" },
        { name: "application-name", content: "Allmedica.com" },
        { name: "msapplication-TileColor", content: "#da532c" },
        { name: "theme-color", content: "#ffffff" }
    ],
    link: [
        { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-icon.png" },
        { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
        { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" },
        { rel: "manifest", href: "/site.webmanifest" },
        { rel: "mask-icon", href: "/safari-pinned-tab.svg", color: "#5bbad5" }
    ]
});
</script>
<template>
    <v-app>
        <NuxtPage />
    </v-app>
</template>
