import { default as contactW7X2MCpnfEMeta } from "/home/vsts/work/1/s/pages/contact.vue?macro=true";
import { default as costkA21upItATMeta } from "/home/vsts/work/1/s/pages/cost.vue?macro=true";
import { default as faqqsweyF5Ao6Meta } from "/home/vsts/work/1/s/pages/faq.vue?macro=true";
import { default as galleryCMwmF7mZMKMeta } from "/home/vsts/work/1/s/pages/gallery.vue?macro=true";
import { default as indexegOWm4p6gtMeta } from "/home/vsts/work/1/s/pages/index.vue?macro=true";
import { default as _91id_93kI38MN9327Meta } from "/home/vsts/work/1/s/pages/service/[main-name]/[name]/[id].vue?macro=true";
import { default as _91id_93JPspIV8HdrMeta } from "/home/vsts/work/1/s/pages/specialization/[name]/[id].vue?macro=true";
import { default as _91id_93snB9XxRZgRMeta } from "/home/vsts/work/1/s/pages/staff/[name]/[id].vue?macro=true";
import { default as indexJvNCOrSENyMeta } from "/home/vsts/work/1/s/pages/staff/index.vue?macro=true";
export default [
  {
    name: "contact",
    path: "/contact",
    meta: contactW7X2MCpnfEMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "cost",
    path: "/cost",
    meta: costkA21upItATMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/cost.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqqsweyF5Ao6Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: galleryCMwmF7mZMKMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexegOWm4p6gtMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "service-mainname-name-id",
    path: "/service/:mainname()/:name()/:id()",
    meta: _91id_93kI38MN9327Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/service/[main-name]/[name]/[id].vue").then(m => m.default || m)
  },
  {
    name: "specialization-name-id",
    path: "/specialization/:name()/:id()",
    meta: _91id_93JPspIV8HdrMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/specialization/[name]/[id].vue").then(m => m.default || m)
  },
  {
    name: "staff-name-id",
    path: "/staff/:name()/:id()",
    meta: _91id_93snB9XxRZgRMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/staff/[name]/[id].vue").then(m => m.default || m)
  },
  {
    name: "staff",
    path: "/staff",
    meta: indexJvNCOrSENyMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/staff/index.vue").then(m => m.default || m)
  }
]